import React from 'react'
import Section from '../components/Section'
import Navbar from '../components/Navbar'

import { isMobile } from 'react-device-detect'

// @ts-ignore
import leftTopSquigle from '../assets/left_top_squigle.svg'
// @ts-ignore
import bigSquigle from '../assets/big_squigle.svg'

export default function Main() {
    return (
        <>
            <Navbar />
            <Section style={{ height: '100vh' }}>
                <h1 className="main-title">
                    Real Estate Investment,
                    <br />
                    <span>
                        Simplified
                    </span>
                    .
                    {/* Estate<span>Gain</span> */}
                </h1>
                <br />
                <h5 className="main-subtitle">
                    No Huge <span>Capital</span>, Just Real <span>Gains</span>
                    {/* Real Estate Investment, <span>Simplified</span>. */}
                </h5>
                <img src={leftTopSquigle} alt="" className="squigle" style={{ left: 0, height: '100vh' }} />
            </Section>
            <div>

            <Section style={{ height: '200vh', overflow: 'hidden' }}>
                <div style={{ display: 'flex', flexDirection: 'column', width: '100vw' }}>
                    <div className="how-it-works-item how-it-works-left">
                        <h1>
                            <span>Discover</span>
                            &nbsp;Opportunities
                        </h1>
                        <h5>
                        Explore our curated list of real estate properties.
    Choose the one that aligns with your investment goals.
                        </h5>
                    </div>
                    <div className="how-it-works-item how-it-works-right">
                        <h1>
                            <span>Crowdfund</span>
                            &nbsp;with Confidence
                        </h1>
                        <h5>
                        Invest your desired amount alongside other
    interested investors to collectively fund the property.
                        </h5>
                    </div>
                </div>
                <img src={bigSquigle} alt="" className="squigle" style={{ left: 0, top: 0, height: '200vh' }} />

                <div style={{ display: 'flex', flexDirection: 'column', width: '100vw' }}>
                    <div className="how-it-works-item how-it-works-left">
                        <h1>
                            <span>Own</span>
                            &nbsp;a Share of the Property
                        </h1>
                        <h5>
                        Shares are distributed among investors based on their initial contributions,
    granting you ownership in proportion to your investment.
                        </h5>
                    </div>
                    <div className="how-it-works-item how-it-works-right">
                        <h1>
                            <span>Enjoy</span>
                            &nbsp;Returns or Sell
                        </h1>
                        <h5>
                        Sit back and enjoy passive income as
    dividends flow in from the property's rent.
                        </h5>
                    </div>
                </div>

                <a href="mailto:contact@estategain.org">
                    <button style={{ marginTop: '20vh', fontWeight: 'bold' }}>
                        Get in Touch
                    </button>
                </a>
            </Section>
            </div>
        </>
    )
}
