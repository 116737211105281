import React from 'react'

// @ts-ignore
import logo from '../assets/logo.png'

export default function Navbar() {
    return (
        <div className="navbar" onClick={() => document.body.scrollTo(0, 0)} style={{ cursor: 'pointer' }}>
            <img src={logo} alt="EstateGain Logo" className="navbar-image" />
            <h1>EstateGain</h1>
        </div>
    )
}
