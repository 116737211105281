import React from 'react'

type SectionProps = {
    className?: string;
    style?: React.CSSProperties;
    children: React.ReactNode;
}

export default function Section(props: SectionProps) {
    return (
        <div className={`section ${props.className}`} style={props.style}>
            { props.children }
        </div>
    )
}
